import { render, staticRenderFns } from "./CommentMarker.vue?vue&type=template&id=3cd202ad&scoped=true"
import script from "./CommentMarker.vue?vue&type=script&lang=ts"
export * from "./CommentMarker.vue?vue&type=script&lang=ts"
import style0 from "./CommentMarker.vue?vue&type=style&index=0&id=3cd202ad&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cd202ad",
  null
  
)

export default component.exports